import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path:"/as",
      name:"Layout",
      component:()=>import("@/views/Layout"),
      children:[
        {
          path:"/threequarterList",
          name:"threequarterList",
          component:()=>import("@/views/List/ThreequarterList")
        },
        {
          path:"/flowList",
          name:"flowList",
          component:()=>import("@/views/List/FlowList")
        },
        {
          path:"/balancesheetList",
          name:"balancesheetList",
          component:()=>import("@/views/List/BalancesheetList")
        },
        {
          path:"/voucherList",
          name:"voucherList",
          component:()=>import("@/views/List/VoucherList")
        },
        {
          path:"/voucherListYD",
          name:"voucherListYD",
          component:()=>import("@/views/List/VoucherListYD")
        },
        {
          path:"/financialsummaryList",
          name:"financialsummaryList",
          component:()=>import("@/views/List/FinancialsummaryList")
        },
        {
          path:"/taxDeclaration",
          name:"taxDeclaration",
          component:()=>import("@/views/Tax/TaxDeclaration"),
        },
        {
          path:"/salarymanageList/:id",
          name:"salarymanageList",
          component:()=>import("@/views/List/SalarymanageList"),
        },
        {
          path:"/salaryList",
          name:"salaryList",
          component:()=>import("@/views/List/SalaryList"),
        },
        {
          path:"/employInfoList",
          name:"employInfoList",
          component:()=>import("@/views/List/EmployInfoList"),
        },
        {
          path:"/employInfoAdd",
          name:"employInfoAdd",
          component:()=>import("@/views/Add/EmployInfoAdd"),
        },
        {
          path:"/employInfoMovify",
          name:"employInfoMovify",
          component:()=>import("@/views/Movify/EmployInfoMovify"),
        },
        {
          path:"/setList",
          name:"setList",
          component:()=>import("@/views/List/SetList"),
        },
      ],
    },
    {
      path:"*",
      name:"404",
      component:() => import("@/views/404"),
      hidden:true
    },
    {
      path:'/voucherDetails/:id',
      name:'voucherDetails',
      component:()=>import("@/views/Details/VoucherDetails"),
    },
    {
      path:"/balancesheetDetails",
      name:"balancesheetDetails",
      component:()=>import("@/views/Details/BalancesheetDetails"),
    },
    {
      path:"/financialsummaryDetails",
      name:"financialsummaryDetails",
      component:()=>import("@/views/Details/FinancialsummaryDetails"),
    },
    {
      path:"/flowDetails",
      name:"flowDetails",
      component:()=>import("@/views/Details/FlowDetails"),
    },
    {
      path:"/threequarterDetails",
      name:"threequarterDetails",
      component:()=>import("@/views/Details/ThreequarterDetails"),
    },
    {
      path:"/employInfoDetails",
      name:"employInfoDetails",
      component:()=>import("@/views/Details/EmployInfoDetails"),
    },
    {
      path:"/salaryDetails",
      name:"salaryDetails",
      component:()=>import("@/views/Details/SalaryDetails"),
    },
    
    {
      path:"/",
      name:"test",
      component:()=>import("@/views/"),
    }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
